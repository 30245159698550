import { useEffect, useState } from "react";

/**
 * Piano Init Handler
 *
 * @see https://docs.piano.io/callbacks/ - list of all possible events
 *
 * @param {(...args: any) => any} callback - Callback function
 * @returns {[boolean, (value: boolean) => void]} Cancellation state and cancellation function
 */
export const usePianoInit = (callback) => {
  const cancelState = useState(false);
  const [cancelled, setCancelled] = cancelState;

  useEffect(() => {
    if (!callback) return;

    window.tp = window.tp || [];

    // Push the event listener to Piano
    const length = window.tp.push([
      "init",
      // If the event listener is cancelled, don't return the callback
      (...args) => (cancelled ? void 0 : callback(...args)),
    ]);

    // Cancel the event listener on unmount
    return () => {
      // If Piano is still an array (i.e., pre-initialization), replace the callback with a noop
      if (length && window.tp[length - 1]) {
        window.tp[length - 1][1] = () => {};
      }
      setCancelled(true);
    };
  }, []);

  return cancelState;
};

export default usePianoInit;
