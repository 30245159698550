import { useEffect } from "react";

/**
 * This is a lower-level hook that allows you to listen to Piano iframe messages.
 *
 * @param {string} eventName - Piano event name
 * @param {(...args: any) => any} callback - Callback function
 */
export const usePianoMessage = (eventName = "", callback) => {
  function handleMessage(event) {
    // If the event doesn't have data, or if the origin isn't Piano, ignore it
    if (
      !event.data ||
      (!event.origin.includes("piano.io") && !event.origin.includes("tinypass.com"))
    )
      return;

    try {
      const data = JSON.parse(event.data);
      if (data.event === eventName) {
        callback(data);
      }
    } catch (e) {
      console.error("[usePianoMessage] ", e);
    }
  }

  useEffect(() => {
    if (!eventName || !callback) return;

    window.addEventListener("message", handleMessage);
    return () => window.removeEventListener("message", handleMessage);
  }, []);
};

export default usePianoMessage;
