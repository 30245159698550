import { createContext, useContext, useEffect, useState } from "react";

import { getPianoIdUserDirty } from "../../utils";
import { usePianoCallback } from "./use-piano-callback";
import { usePianoInit } from "./use-piano-init";
import { usePianoMessage } from "./use-piano-message";

const noop = (arg) => console.warn("caught in noop", arg);

// An SSR-safe user context
const PianoContext = createContext({
  // State
  loadStatus: "loading",
  authStatus: "logged-out",

  /** @type {Record<string, string|number|boolean|undefined> | null} */
  user: null,

  // State setters
  setLoadError: noop,

  // Computed from state
  isMaybeLoggedIn: false,
  isLoggedIn: false,
  isLoggedOut: false,
  isLoaded: false,
  isLoading: false,
  isReady: false,
  isError: false,
  pianoMeterCount: null,

  // Actions
  logout: noop,
  showLoginModal: noop,
  showRegisterModal: noop,
});

/**
 * Piano Context Component
 */
export function ProvidePiano({ children }) {
  const piano = useProvidePiano();
  return <PianoContext.Provider value={piano}>{children}</PianoContext.Provider>;
}

/**
 * Use Piano Context
 *
 * Provides access to Piano state and common actions,
 * namely loading status, auth status, and user object.
 *
 * @returns Piano Context Object
 */
export const usePiano = () => {
  return useContext(PianoContext);
};

// Provider hook that creates auth object and handles state
function useProvidePiano() {
  const [loadStatus, setLoadStatus] = useState("loading"); // loading|success|error
  const [authStatus, setAuthStatus] = useState("loading"); // loading|logged-in|logged-out|error
  const [user, setUser] = useState(null); // @TODO: Extract Piano ID User functionality into a separate hook

  // Computed properties for convenience
  const isMaybeLoggedIn =
    authStatus === "logged-in" ||
    // @ts-ignore
    !!(authStatus === "loading" && user?.dirty);
  const isLoggedIn = authStatus === "logged-in";
  const isLoggedOut = authStatus === "logged-out" || authStatus === "error";
  const isReady = loadStatus !== "loading";
  const isLoading = authStatus === "loading";
  const isLoaded = loadStatus === "success";
  const isError = authStatus === "error" || loadStatus === "error";

  // Event Handlers
  const setLoggedIn = () => {
    setAuthStatus("logged-in");
    setUser(window.tp.pianoId.getUser() || null);
  };

  const setLoggedOut = (error = false) => {
    setAuthStatus(error ? "error" : "logged-out");
    setUser(null);
  };

  const setLoadError = () => {
    // Do nothing if Piano SDK is already loaded
    if (loadStatus !== "loading" || authStatus !== "loading" || !!window.tp.user) return;

    setLoadStatus("error");

    // If the user has a JWT but Piano's JS fails to load,
    // they should see an auth error in addition to the load error
    const shouldShowAuthError = !!user;
    setLoggedOut(shouldShowAuthError);
  };

  // @ts-ignore
  useEffect(() => {
    // Get initial user state from Piano JWT cookie
    setUser(getPianoIdUserDirty());
  }, []);

  usePianoCallback("loggedIn", () => setLoggedIn());
  usePianoCallback("loggedOut", () => setLoggedOut());
  usePianoCallback("loginFailed", () => setLoggedOut(true));
  usePianoCallback("loginSuccess", () => setLoggedIn());
  usePianoCallback("registrationSuccess", () => setLoggedIn());
  usePianoCallback("registrationFailed", () => setLoggedOut(true));
  usePianoCallback("profileUpdated", () => setLoggedIn());

  /**
   * @type {[number|null, React.Dispatch<React.SetStateAction<number|null>>]}
   */
  const [pianoMeterCount, setPianoMeterCount] = useState(null);

  function handlePianoMeterEvent(e) {
    setPianoMeterCount(e.views);
  }

  // Used to sync our meter to the Piano meter so we don't do a reset.
  usePianoCallback("meterActive", handlePianoMeterEvent);
  usePianoCallback("meterExpired", handlePianoMeterEvent);

  // Set initial state on Piano init
  usePianoInit(() => {
    const tp = window.tp;
    setLoadStatus("success");
    setAuthStatus(tp.user.isUserValid() ? "logged-in" : "logged-out");
    setUser(tp.pianoId.getUser() || null);

    // Piano doesn't actively sync the user object
    // This will perform a second check after a delay
    setTimeout(() => setUser(tp.pianoId.getUser() || user || null), 5000);
  });

  // Fallback in case Piano doesn't notify us of login/logout
  usePianoMessage("closed", () => {
    setTimeout(() => {
      setAuthStatus((status) => {
        if (status !== "loading") return status;
        return window.tp.user.isUserValid() ? "logged-in" : "logged-out";
      });
    }, 50);
  });

  // Actions
  const logout = (cb) => {
    setAuthStatus("loading");
    window.location.href = "/logout/?returnTo=" + window.location.pathname;
  };

  const showLoginModal = (args) => {
    setAuthStatus("loading");
    window.location.href = "/login/?returnTo=" + window.location.pathname;
  };

  const showRegisterModal = (args) => {
    setAuthStatus("loading");
    window.location.href = "/register/?returnTo=" + window.location.pathname;
  };

  // Return the user object and auth methods
  return {
    // State
    loadStatus,
    authStatus,
    pianoMeterCount,

    /** @type {Record<string, string|number|boolean|undefined> | null} */
    user,

    // State setters
    setLoadError,

    // Computed properties
    isMaybeLoggedIn,
    isLoggedIn,
    isLoggedOut,
    isLoaded,
    isLoading,
    isReady,
    isError,

    // Actions
    logout,
    showLoginModal,
    showRegisterModal,
  };
}

export default usePiano;
