export const PIANO_PROD = /** @type {const} */ ({
  name: "Production",
  slug: "prod",
  aid: "FU52w9tupu",
  authProvider: "auth0",
  authExternalClientId: "C1VZnhgUJaCUJCEC1aqmm6bg6I9L48fW",
  loaderUrl: "https://experience.tinypass.com/xbuilder/experience/load?aid=FU52w9tupu",
  cxenseSiteId: "4731406317427036748",
  endpointUrl: "https://buy.tinypass.com/api/v3",
  pianoIdUrl: "https://id.tp.scientificamerican.com",
  sdkUrl: "https://cdn.tp.scientificamerican.com/api/tinypass.min.js",
  defaultOffer: "OFU8RMQESTD8",
  winbackOffer: "OFJRF90GW1OM", // we are not including these in the universal offer since they're really low prices
  terms: {
    winbackTerm1Year: "TMQPDNMS7RJV",
    winbackTerm2Year: "TM37JC43RWHD",
  }, // these are the two dynamic terms for the winback offer
});

export const PIANO_PREPROD = /** @type {const} */ ({
  name: "Pre-Production",
  slug: "preprod",
  aid: "zb26Lrf5pu",
  authProvider: "auth0",
  authExternalClientId: "949krH8ZEE0TwdIJxgIxuiQOVIT8nBJS",
  loaderUrl: "https://experience.tinypass.com/xbuilder/experience/load?aid=zb26Lrf5pu",
  endpointUrl: "https://buy.tinypass.com/api/v3",
  pianoIdUrl: "https://id.tinypass.com",
  sdkUrl: "https://cdn.tinypass.com/api/tinypass.min.js",
  defaultOffer: "OF85KSMYIFWO",
});

export const PIANO_SANDBOX = /** @type {const} */ ({
  name: "Sandbox",
  slug: "sandbox",
  aid: "I2n3TREbsu",
  authProvider: "auth0",
  authExternalClientId: "949krH8ZEE0TwdIJxgIxuiQOVIT8nBJS",
  loaderUrl: "https://sandbox.tinypass.com/xbuilder/experience/load?aid=I2n3TREbsu",
  endpointUrl: "https://sandbox.tinypass.com/api/v3",
  pianoIdUrl: "https://sandbox.piano.io",
  sdkUrl: "https://sandbox.tinypass.com/api/tinypass.min.js",
  defaultOffer: "OFLWOLQY3245",
  winbackOffer: "OFBEBVV63VVA", // we are not including these in the universal offer since they're really low prices
  terms: {
    winbackTerm1Year: "TMTAJO1X5L5A",
    winbackTerm2Year: "TM2EY1ESEFD9",
  }, // these are the two dynamic terms for the winback offer
});

/**
 * @typedef {typeof PIANO_PROD | typeof PIANO_PREPROD | typeof PIANO_SANDBOX} PianoEnvironment
 */

export const PIANO_ENVIRONMENTS = [PIANO_PROD, PIANO_PREPROD, PIANO_SANDBOX];
