import { getCurrentConsent } from "@sciam/shared";

/**
 * Piano consent reference
 *
 * | Code | Purpose                               | Piano Product        |
 * |:-----|:--------------------------------------|:---------------------|
 * | `AM` | Audience Measurement                  | Piano Analytics (PA)
 * | `CP` | Content personalization + Performance | Composer
 * | `AD` | Advertising                           | DMP, Social Flow
 * | `PR` | Personal Relationship                 | ID, VX, ESP
 * | `DL` | Data Layer                            | DL
 *
 * https://docs.piano.io/consent-management-for-client-storage-cookies/#consentmodes
 *
 * @typedef {"AM"|"CP"|"AD"|"PR"|"DL"} ConsentPurpose
 * @typedef {"opt-in"|"essential"|"opt-out"} ConsentMode
 * @typedef {Record<ConsentPurpose, ConsentMode>} ConsentConfig
 */

/**
 * Push work to find OneTrust consent codes into the consentQueue.  That way, once OneTrust dependencies
 * come online, this work to find OneTrust consent codes will work.
 */
export function linkConsentStateToPiano(cb) {
  const tp = window.tp;

  let initialized = false;
  function onConsent() {
    if (initialized) return;
    initialized = true;
    cb?.();
  }

  // Get Piano's stored consent state
  const initialConsent = tp.consent.getByPurpose();
  console.log("[piano] Initial Piano consent", initialConsent);

  if (initialConsent) {
    onConsent();
  } else {
    console.log("[piano] Applying base consent until OneTrust consentQueue logic executes.");
    syncPianoConsent();
    console.log("[piano] base consent", tp.consent.getByPurpose());

    // Experiment: Always run callback (tp.experience.init) immediately
    // Doing this may lead to conflicts with consentQueue logic or premature dataLayer population
    onConsent();
  }

  // Listen for OneTrust consent changes
  window.consentQueue = window.consentQueue || [];
  window.consentQueue.push(function pianoConsent() {
    syncPianoConsent();
    console.log("[piano] Piano consentQueue logic executed.", tp.consent.getByPurpose());

    // Initialize Piano (if not already initialized)
    onConsent();
  });
}

/**
 * Sync OneTrust consent state to Piano
 * @param {Partial<ConsentConfig>} overrides
 */
export function syncPianoConsent(overrides = {}) {
  const consent = getCurrentConsent();
  console.log("[piano] OneTrust consent state:", consent);

  const baseConsent = consent.performance && consent.functional ? "opt-in" : "essential";

  applyPianoConsentConfig({
    // Piano Analytics
    AM: consent.targeting ? "opt-in" : "opt-out",
    // Composer
    CP: baseConsent,
    // DMP, Social Flow
    AD:
      consent.targeting && consent.social ? "opt-in" : consent.targeting ? "essential" : "opt-out",
    // ID, VX, ESP
    PR: baseConsent,
    // dataLayer
    DL: baseConsent,
    ...overrides,
  });
}

/**
 * Apply a Piano consent config
 * @param {ConsentConfig} consent
 */
function applyPianoConsentConfig(consent) {
  const setByPurpose = window.tp?.consent?.setByPurpose;
  Object.entries(consent).forEach(([purpose, mode]) => setByPurpose?.(purpose, mode));
}
