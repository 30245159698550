/** Get the Piano JWTs from our internal `sa_piano_auth` cookie */
export const getAuthSyncTokens = () => {
  const tokens = /** @type {Record<string, string>} */ ({});
  for (const [_, aud, token] of document.cookie.matchAll(/sa_piano_auth(?:\.(\w+))?=([^;]+)/g)) {
    tokens[aud || "default"] = token;
  }
  return tokens;
};

export const getExternalJwt = (clientId = "") => {
  const tokens = getAuthSyncTokens();
  return tokens[clientId] || tokens.default;
};


/**
* @typedef {Record<string, any>} CheckoutParams The checkout parameters, can be passed directly into `tp.offer.startCheckout`
* @typedef {string} CheckoutId Possibly an offer id or tracking id?
* @typedef {Record<string,any>} CheckoutSomethingElse It's only ever shown up as an empty object in my testing
* @typedef {[CheckoutParams, CheckoutId, CheckoutSomethingElse]} CheckoutArgs
*/
const PIANO_CHECKOUT_STORAGE_KEY = "sa_piano_checkout_args";

/**
 * Get the stored checkout arguments
 * @returns {CheckoutArgs}
 */
export const getCheckoutArgs = () =>
  JSON.parse(localStorage.getItem(PIANO_CHECKOUT_STORAGE_KEY) || "[]");

/**
 * Set the stored checkout arguments
 * @param {CheckoutArgs} value
 */
export const setCheckoutArgs = (value = [{}, "", {}]) =>
  localStorage.setItem(PIANO_CHECKOUT_STORAGE_KEY, JSON.stringify(value));

/** Clear the stored checkout arguments */
export const clearCheckoutArgs = () => localStorage.removeItem(PIANO_CHECKOUT_STORAGE_KEY);
