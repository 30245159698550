import { useEffect, useState } from "react";

/**
 * Piano Event Handler
 *
 * @see https://docs.piano.io/callbacks/ - list of all possible events
 *
 * @param {string} eventName - Piano event name
 * @param {(...args: any) => any} callback - Callback function
 * @returns {[boolean, (value: boolean) => void]} Cancellation state and cancellation function
 */
export const usePianoCallback = (eventName = "", callback) => {
  const cancelState = useState(false);
  const [cancelled, setCancelled] = cancelState;

  useEffect(() => {
    if (!eventName || !callback) return;
    if (eventName === "init") {
      throw new Error("usePianoCallback: 'init' isn't a valid callback. Use usePianoInit instead.");
    }

    window.tp = window.tp || [];

    // Push the event listener to Piano
    const length = window.tp.push([
      "addHandler",
      eventName,
      // If the event listener is cancelled, don't return the callback
      (...args) => (cancelled ? void 0 : callback(...args)),
    ]);

    // Cancel the event listener on unmount
    return () => {
      // If Piano is still an array (i.e., pre-initialization), replace the callback with a noop
      if (length && window.tp[length - 1]) {
        window.tp[length - 1][1] = () => {};
      }
      setCancelled(true);
    };
  }, []);

  return cancelState;
};

export default usePianoCallback;
