import { createContext, useContext, useEffect, useState } from "react";

import { getPianoEnv } from "../../utils";

/**
 * @typedef {ReturnType<typeof getPianoEnv>} PianoEnv
 * @typedef {PianoEnv | null} PianoEnvOrNull
 */
const PianoEnvContext = createContext(/** @type {PianoEnvOrNull} */ (null));

/**
 * Get the current Piano Environment
 *
 * This will be null until after the first render.
 * @returns {PianoEnvOrNull}
 */
export function usePianoEnv() {
  return useContext(PianoEnvContext);
}

/**
 * Provide Piano Environment
 * @param {React.PropsWithChildren} props
 */
export function ProvidePianoEnv({ children }) {
  const [pianoEnv, setPianoEnv] = useState(/** @type {PianoEnvOrNull} */ (null));
  useEffect(() => setPianoEnv(getPianoEnv()), []);

  return <PianoEnvContext.Provider value={pianoEnv}>{children}</PianoEnvContext.Provider>;
}

export default usePianoEnv;
